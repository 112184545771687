<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="hee hee"
      :paginate-elements-by-height="50"
      :pdf-quality="2"
      pdf-content-width="827"
      :manual-pagination="true"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        html2canvas: {
          scale: 4,
          useCORS: true,
        },

        enableLinks: true,
        image: {
          type: 'jpeg',
          quality: 0.99,
        },
        margin: 50,
        jsPDF: {
          unit: 'px',
          format: [1169, 827],
          orientation: 'portrait',
        },
      }"
    >
      <section slot="pdf-content">
        <!-- Include Vuetify CSS -->

        <v-app style="background-color: white">
          <v-container class="ma-0 pt-0"
            ><v-row class="mt-0 pt-0">
              <img
                class="pdf_logo"
                src="../../assets/LogoV2-Black_ClearBknd.png"
              />
              <img
                class="pdf_logo"
                style="float: right"
                :src="getSignedImageUrl()"
              />
            </v-row>
            <v-row class="mb-6">
              <v-col class="text-center">
                <h3>Daily Report for Estrus Detection</h3>
              </v-col>
            </v-row>
            <p class="mb-2 pdf_p">Hello {{ customer.first_name }},</p>
            <p class="mb-10 pdf_p">{{ summary_text }}</p>

            <p class="mb-2 pdf_p pdf_table_title">Chin Resting Events</p>
            <v-card elevation="1" outlined>
              <v-data-table
                :headers="headers_chin_rest"
                :items="chin_resting_data"
                :items-per-page="100"
                class="elevation-1 livestock_table pdf_table"
                hide-default-footer
                no-data-text="No data available"
                dense
              ></v-data-table>
            </v-card>

            <p class="mb-2 mt-10 pdf_p pdf_table_title">Mounting Events</p>
            <v-card elevation="1" outlined>
              <v-data-table
                :headers="headers_mounting_notifications"
                :items="mounting_notifications"
                :items-per-page="100"
                class="elevation-1 pdf_table"
                hide-default-footer
                no-data-text="No data available"
                dense
                ><template v-slot:[`item.image`]="{ item }">
                  <img
                    class="ma-1"
                    style="border-radius: 12px; width: auto; height: 70px"
                    :src="getSignedImageUrl(cookie_bucket + item.media_url)"
                  /> </template></v-data-table
            ></v-card>
          </v-container>
        </v-app>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import axios from "axios";
import * as hasura_queries from "../../graphql_hasura/queries";
import moment from "moment";
import { eventBus } from "../../main.js";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      chin_rest_summary: {
        today_total: 0,
        yesterday_total: 0,
      },

      mounting_notifications: [],
      chin_resting_data: [],

      htmlToPdfOptions: {
        html2canvas: {
          scale: 4,
          useCORS: true,
        },

        enableLinks: true,
        image: {
          type: "jpeg",
          quality: 0.1,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      },
    };
  },
  methods: {
    company_url() {
      return this.getSignedImageUrl(this.cookie_bucket +
      this.user.username +
      "/company/images/" +
      this.user.username +
      "_logo_300x300.jpg")
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return null;
    },
    getSignedImageUrl(url) {
      const keyPairId = this.getCookie("CloudFront-Key-Pair-Id");
      const policy = this.getCookie("CloudFront-Policy");
      const signature = this.getCookie("CloudFront-Signature");
      if (keyPairId && policy && signature) {
        return `${url}?Key-Pair-Id=${keyPairId}&Policy=${policy}&Signature=${signature}`;
      }
      return url;
    },

    generate_report() {
      this.$refs.html2Pdf.generatePdf();
    },

    to_cam_key_object(array) {
      var return_obj = {};
      for (const i in array) {
        return_obj[array[i].camera_id] = array[i].resting_chin_count;
      }
      return return_obj;
    },
    process_chin_rest(today, yesterday) {
      let today_obj = this.to_cam_key_object(today);
      let yesterday_obj = this.to_cam_key_object(yesterday);

      var return_array = [];
      for (const i in today_obj) {
        this.chin_rest_summary.today_total += today_obj[i];
        this.chin_rest_summary.yesterday_total += yesterday_obj[i];
        let cam_object = {
          camera_name: this.camera_name_object[i],
          camera_id: i,
          chin_rest_num: today_obj[i],
          change:
            today_obj[i] - yesterday_obj[i] != 0
              ? today_obj[i] != 0
                ? Math.round(
                    ((today_obj[i] - yesterday_obj[i]) / today_obj[i]) * 100
                  ).toString() + "%"
                : Math.round(
                    ((today_obj[i] - yesterday_obj[i]) / yesterday_obj[i]) *
                      -100
                  ).toString() + "%"
              : "Same",
        };
        return_array.push(cam_object);
      }
      this.chin_resting_data = return_array;
    },
  },

  async mounted() {
    eventBus.$on("generate_estrus_report", this.generate_report);
    let today = moment().format("YYYY-MM-DD") + "T00:00:00";
    let yesterday =
      moment().subtract(1, "days").format("YYYY-MM-DD") + "T00:00:00";
    let today_data = await axios.post(
      this.$store.getters.getHasuraParams.url,
      {
        query: hasura_queries.summary_estrus_1day_camera,
        variables: {
          where: {
            customer_id: { _eq: this.user.username },
            media_timestamp: { _eq: today },
          },
        },
      },
      {
        headers: this.$store.getters.getHasuraParams.headers,
      }
    );
    let yesterday_data = await axios.post(
      this.$store.getters.getHasuraParams.url,
      {
        query: hasura_queries.summary_estrus_1day_camera,
        variables: {
          where: {
            customer_id: { _eq: this.user.username },
            media_timestamp: { _eq: yesterday },
          },
        },
      },
      {
        headers: this.$store.getters.getHasuraParams.headers,
      }
    );

    let notification_date = moment.tz();

    let mounting_notifications = (
      await axios.post(
        this.$store.getters.getHasuraParams.url,
        {
          query: hasura_queries.notification,
          variables: {
            where: {
              datetime: {
                _lt: moment(notification_date, "YYYY-MM-DDTHH").format(
                  "YYYY-MM-DDTHH:ss:mm"
                ),
                _gte: moment(notification_date, "YYYY-MM-DDTHH")
                  .subtract(1, "d")
                  .format("YYYY-MM-DDTHH:ss:mm"),
              },
              type: { _eq: "MOUNTING" },
            },
            order_by: { datetime: "desc" },
          },
        },
        {
          headers: this.$store.getters.getHasuraParams.headers,
        }
      )
    ).data;

    this.mounting_notifications = mounting_notifications.data.notification;
    this.process_chin_rest(
      today_data.data.data.summary_estrus_1day_camera,
      yesterday_data.data.data.summary_estrus_1day_camera
    );
  },

  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    summary_text() {
      let difference =
        this.chin_rest_summary.today_total -
        this.chin_rest_summary.yesterday_total;
      let difference_percent = Math.round(
        (Math.abs(difference) / this.chin_rest_summary.today_total) * 100
      );
      let same = difference === 0 ? true : false;
      let inc_dec =
        difference > 0 ? ["more", "increase"] : ["less", "decrease"];
      return `This is your daily report from OneCup AI for ${
        this.dayofweek
      } ${moment().format("MMM DD")}. BETSY 
      has spotted ${
        this.chin_rest_summary.today_total
      } chin resting events, which was  ${
        same
          ? "the same"
          : Math.abs(difference) +
            " " +
            inc_dec[0] +
            " (" +
            difference_percent +
            "% " +
            inc_dec[0] +
            ")"
      }  compared to 
      the day before. BETSY also found ${
        this.mounting_notifications.length
      } mounting events.`;
    },
    weekday() {
      var dayofweek;
      if (moment().weekday() == 0) {
        dayofweek = "Sunday";
      } else if (moment().weekday() == 1) {
        dayofweek = "Monday";
      } else if (moment().weekday() == 2) {
        dayofweek = "Tuesday";
      } else if (moment().weekday() == 3) {
        dayofweek = "Wednesday";
      } else if (moment().weekday() == 4) {
        dayofweek = "Thursday";
      } else if (moment().weekday() == 5) {
        dayofweek = "Friday";
      } else if (moment().weekday() == 6) {
        dayofweek = "Saturday";
      }
      return dayofweek;
    },
    user() {
      return this.$store.getters.getUser;
    },
    customer() {
      return this.$store.getters.getUserDDB;
    },
    camera_name_object() {
      return this.$store.getters.getCameraNameObject;
    },
    headers_chin_rest() {
      return [
        {
          text: "Camera Name",
          value: "camera_name",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Camera ID",
          value: "camera_id",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Number of Events",
          value: "chin_rest_num",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Change",
          value: "change",
          cellClass: "pdf_table_cell",
        },
      ];
    },

    headers_mounting_notifications() {
      return [
        {
          text: "Camera Name",
          value: "camera.name",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Camera ID",
          value: "camera_id",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Timestamp",
          value: "datetime",
          cellClass: "pdf_table_cell",
        },
        {
          text: "Thumbnail",
          value: "image",
          cellClass: "pdf_table_cell",
        },
      ];
    },
  },
};
</script>

<style>
.pdf_p {
  font-size: 12px;
}

.pdf_table_cell {
  font-size: 10px !important;
}

.pdf_logo {
  width: auto;
  height: 50px;
}

.pdf_table_title {
  font-weight: bold;
}

.pdf_table > div > table > thead > tr > th {
  font-size: 10px !important;
}
</style>