var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"preview-modal":true,"filename":"hee hee","paginate-elements-by-height":50,"pdf-quality":2,"pdf-content-width":"827","manual-pagination":true,"htmlToPdfOptions":{
      html2canvas: {
        scale: 4,
        useCORS: true,
      },

      enableLinks: true,
      image: {
        type: 'jpeg',
        quality: 0.99,
      },
      margin: 50,
      jsPDF: {
        unit: 'px',
        format: [1169, 827],
        orientation: 'portrait',
      },
    }}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('v-app',{staticStyle:{"background-color":"white"}},[_c('v-container',{staticClass:"ma-0 pt-0"},[_c('v-row',{staticClass:"mt-0 pt-0"},[_c('img',{staticClass:"pdf_logo",attrs:{"src":require("../../assets/LogoV2-Black_ClearBknd.png")}}),_c('img',{staticClass:"pdf_logo",staticStyle:{"float":"right"},attrs:{"src":_vm.getSignedImageUrl()}})]),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"text-center"},[_c('h3',[_vm._v("Daily Report for Estrus Detection")])])],1),_c('p',{staticClass:"mb-2 pdf_p"},[_vm._v("Hello "+_vm._s(_vm.customer.first_name)+",")]),_c('p',{staticClass:"mb-10 pdf_p"},[_vm._v(_vm._s(_vm.summary_text))]),_c('p',{staticClass:"mb-2 pdf_p pdf_table_title"},[_vm._v("Chin Resting Events")]),_c('v-card',{attrs:{"elevation":"1","outlined":""}},[_c('v-data-table',{staticClass:"elevation-1 livestock_table pdf_table",attrs:{"headers":_vm.headers_chin_rest,"items":_vm.chin_resting_data,"items-per-page":100,"hide-default-footer":"","no-data-text":"No data available","dense":""}})],1),_c('p',{staticClass:"mb-2 mt-10 pdf_p pdf_table_title"},[_vm._v("Mounting Events")]),_c('v-card',{attrs:{"elevation":"1","outlined":""}},[_c('v-data-table',{staticClass:"elevation-1 pdf_table",attrs:{"headers":_vm.headers_mounting_notifications,"items":_vm.mounting_notifications,"items-per-page":100,"hide-default-footer":"","no-data-text":"No data available","dense":""},scopedSlots:_vm._u([{key:`item.image`,fn:function({ item }){return [_c('img',{staticClass:"ma-1",staticStyle:{"border-radius":"12px","width":"auto","height":"70px"},attrs:{"src":_vm.getSignedImageUrl(_vm.cookie_bucket + item.media_url)}})]}}],null,true)})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }